import $ from 'jquery';

$(() => {
  const cookieStorage = {
    getItem: item => {
      const cookies = document.cookie
        .split(';')
        .map(cookie => cookie.split('='))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
      return cookies[item];
    },
    setItem: (item, value) => {
      const date = new Date();
      date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
      document.cookie = `${item}=${value};expires=${date.toGMTString()}; path=/`;
    },
    updateField: (item, value) => {
      cookieProperties[item] = value;
      cookieObject = JSON.stringify(cookieProperties);
      saveToStorage(storageType);
    },
    allowAll: () => {
      for (const item in cookieProperties) {
        cookieProperties[item] = true;
      }
      cookieObject = JSON.stringify(cookieProperties);
      saveToStorage(storageType);
    },
  };

  const storageType = cookieStorage;
  const consentPropertyName = 'vocalls_consents';

  var cookieProperties = {
    consent_received: false,
    marketing: false,
    analytics: false,
  };
  var cookieObject = JSON.stringify(cookieProperties);

  const saveToStorage = () =>
    storageType.setItem(consentPropertyName, cookieObject);

  window.onload = () => {
    const acceptFn = event => {
      cookieStorage.allowAll();
      AllConsentsGranted();
      consentPopup.classList.add('hidden');
    };

    const showSettings = event => {
      consentPopup.classList.add('hidden');
      consentPopupExtended.classList.remove('hidden');

      const allowBtn = document.getElementById('allow-all');
      const rejectBtn = document.getElementById('reject-all');
      const saveBtn = document.getElementById('confirm-consent');

      if (allowBtn) {
        allowBtn.addEventListener('click', () => {
          acceptFn();
          consentPopupExtended.classList.add('hidden');
        });
      }

      if (rejectBtn) {
        rejectBtn.addEventListener('click', () => {
          cookieStorage.updateField('consent_received', true);
          consentPopupExtended.classList.add('hidden');
        });
      }

      if (saveBtn) {
        saveBtn.addEventListener('click', updatePreferences);
      }
    };

    const updatePreferences = event => {
      const marketingCheckbox = document.getElementById('consent-marketing');
      const analyticsCheckbox = document.getElementById('consent-analytics');
      cookieStorage.updateField('consent_received', true);
      cookieStorage.updateField('marketing', marketingCheckbox.checked);
      cookieStorage.updateField('analytics', analyticsCheckbox.checked);
      consentPopupExtended.classList.add('hidden');
      validateConsents();
    };

    const consentPopup = document.getElementById('consent-popup');
    const consentPopupExtended = document.getElementById(
      'consent-popup-extended'
    );

    const acceptBtn = document.getElementById('accept-all');
    const modifyBtn = document.getElementById('modify-consent');
    const closeBtn = document.getElementById('close-extended');

    // Save cookie if not there
    if (cookieStorage.getItem(consentPropertyName) === undefined) {
      saveToStorage(storageType);
    }

    // Determine if popup should be shown

    var cookieStatus = JSON.parse(storageType.getItem(consentPropertyName));
    const shouldShowPopup = !cookieStatus.consent_received;

    if (shouldShowPopup) {
      consentPopup.classList.remove('hidden');
    }

    if (acceptBtn) {
      acceptBtn.addEventListener('click', acceptFn);
    }
    if (modifyBtn) {
      modifyBtn.addEventListener('click', showSettings);
    }
    if (closeBtn) {
      closeBtn.addEventListener('click', () => {
        consentPopupExtended.classList.add('hidden');
        consentPopup.classList.remove('hidden');
      });
    }

    //If consent is received and analytics are allowed, update consents
    if (cookieStatus.consent_received) {
      validateConsents();
    }

    function validateConsents() {
      cookieStatus = JSON.parse(storageType.getItem(consentPropertyName));
      if (cookieStatus.consent_received && cookieStatus.analytics) {
        allowAnalytics();
      }
      if (cookieStatus.consent_received && cookieStatus.marketing) {
        allowMarketing();
      }
    }

    function allowAnalytics() {
      gtag('consent', 'update', { analytics_storage: 'granted' });
      var dataObject = {
        event: 'gtm-load',
        category: 'click',
        label: 'label_gtm-load',
      };
      if (typeof dataLayer != 'undefined') {
        dataLayer.push(dataObject);
      }
    }

    function allowMarketing() {
      gtag('consent', 'update', { ad_storage: 'granted' });
    }

    function AllConsentsGranted() {
      allowAnalytics();
      allowMarketing();
    }
  };
});
