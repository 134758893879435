import $ from 'jquery';
import Cookies from 'js-cookie';

$('header .nav .language-item a, header .mobile-nav .language-item a').click(
  event => {
    event.preventDefault();

    const langRegex = /\/(?<lang>cz|es)\//;

    const $link = $(event.currentTarget);
    const locale = $link.data('locale');

    console.log($link, locale);

    var windowOrigin = window.location.origin;
    var windowPath = window.location.pathname;
    var localeString = windowPath.match(langRegex);
    if (localeString) {
      windowPath = windowPath.replace(langRegex, '/');
    }
    var windowUrl = '';
    switch (locale) {
      case 'cs-CZ':
        windowUrl = windowOrigin + '/cz' + windowPath;
        console.log(windowUrl);
        break;
      case 'es-ES':
        windowUrl = windowOrigin + '/es' + windowPath;
        console.log(windowUrl);
        break;
      case 'en-US':
        windowUrl = windowOrigin + windowPath;
        console.log(windowUrl);
        break;
    }

    Cookies.set('_vc_locale', locale, { expires: 365 });

    window.location = windowUrl;

    //window.location.reload();
  }
);

$(() => {
  $('header .language-item a, .footer2 .language-item a').click(event => {
    event.preventDefault();

    const langRegex = /\/(?<lang>cz|es)\//;

    const $link = $(event.currentTarget);
    const locale = $link.data('locale');

    var windowOrigin = window.location.origin;
    var windowPath = window.location.pathname;
    var localeString = windowPath.match(langRegex);
    if (localeString) {
      windowPath = windowPath.replace(langRegex, '/');
    }
    var windowUrl = '';
    switch (locale) {
      case 'cs-CZ':
        windowUrl = windowOrigin + '/cz' + windowPath;
        console.log(windowUrl);
        break;
      case 'es-ES':
        windowUrl = windowOrigin + '/es' + windowPath;
        console.log(windowUrl);
        break;
      case 'en-US':
        windowUrl = windowOrigin + windowPath;
        console.log(windowUrl);
        break;
    }

    Cookies.set('_vc_locale', locale, { expires: 365 });

    window.location = windowUrl;

    //window.location.reload();
  });
});
