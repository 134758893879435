import $ from 'jquery';
import { validateForm } from './validators';

const transformStatusHtml = (status, recordUrl) => {
  const DOWNLOAD_ICON_SRC = '/src/images/icons/ic_file_download.svg';

  return status
    .replace(recordUrl, `https://vocalls.cz${recordUrl}`)
    .replace(DOWNLOAD_ICON_SRC, '/images/icons/download.svg');
};

// This part is for Nikolay fot testing
//  ea867049-3ee9-4c88-bdb3-0b1229b43def ID is not valid

$(() => {
  const StatusInterval = 3000;
  const ApiBaseUrl = 'https://app.vocalls.cz';
  const ScenarioIds = Object.freeze({
    helpdesk: 'b32cb654-0c28-4549-8bd6-3902227a7d83',
    travel: '55bdf2d3-9b46-47eb-92a9-e7dad384116b',
    routing: '1eeb3156-2427-44b2-8e76-dcc98fbf2360',
    nps: '388825dc-9a48-423a-ac2a-ac2290418a7b',
    landing: 'ea867049-3ee9-4c88-bdb3-0b1229b43def',
  });

  let statusTimeoutId = null;
  let lastStatus = null;

  const $form = $('.demo-call-form');
  const $fieldset = $form.find('fieldset');
  const $inputs = $form.find('input, textarea');
  const $modal = $('.demo-call-modal');
  const $modalBody = $modal.find('.modal-dialog__body');
  const initialModalBodyContent = $modalBody.html();

  if (!$form.length || !$modal.length) return;

  $form.on('submit', async event => {
    event.preventDefault();

    if (statusTimeoutId) clearTimeout(statusTimeoutId);

    const errors = validateForm($form);
    const isValid = !Object.keys(errors).length;

    // Remove all errors.
    $inputs.parent().removeClass('--invalid');

    // Apply input errors if necessary.
    Object.keys(errors).forEach(inputName =>
      $inputs
        .filter(`[name="${inputName}"]`)
        .parent()
        .addClass('--invalid')
    );

    if (!isValid) return;

    const $name = $form.find('[name="name"]');
    const $phone = $form.find('[name="phone"]');
    const name = ($name.val() || $name.attr('placeholder')).trim();
    const phone = $phone.val().trim();

    const data = {
      phone,
      id: ScenarioIds.landing,
      parameters: `param-personName=${name}`,
    };

    $form.addClass('--sending').removeClass(['--server-error', '--sent']);
    $fieldset.attr('disabled', 'true');

    try {
      const callId = await $.ajax({
        data,
        method: 'POST',
        url: `${ApiBaseUrl}/UseCases/TestCall`,
      });

      const checkStatus = async () => {
        const { Status: newStatus, RecordURL: recordUrl } = await $.ajax({
          method: 'POST',
          url: `${ApiBaseUrl}/UseCases/GetCallStatus`,
          data: {
            id: callId,
            version: 2,
          },
        });

        if (newStatus !== lastStatus) {
          $modalBody.append(transformStatusHtml(newStatus, recordUrl));
        }

        lastStatus = newStatus;

        // TODO: Add some condition to stop checking status?
        statusTimeoutId = setTimeout(checkStatus, StatusInterval);
      };

      $form.addClass('--sent');
      $modalBody.html(initialModalBodyContent);
      $modal.addClass('--opened');

      statusTimeoutId = setTimeout(checkStatus, StatusInterval);
    } catch (error) {
      $form.addClass('--server-error');
      $modal.removeClass('--opened');
    }

    $form.removeClass('--sending');
    $fieldset.removeAttr('disabled');
  });

  $inputs.on('input change', event => {
    const $input = $(event.target);

    $input.parent().removeClass('--invalid');
  });
});
