import $ from 'jquery';
import Plyr from 'plyr';

$(() => {
  const $containers = [...document.querySelectorAll('.sample-player')];

  if (!$containers.length) return;

  const applyClipPath = $svg => {
    const xmlns = 'http://www.w3.org/2000/svg';
    const id = `clip-${(Math.random() + 1).toString(36).substring(7)}`;
    const $path = $svg.querySelector('path').cloneNode(true);
    const $clippath = document.createElementNS(xmlns, 'clipPath');
    const $g = document.createElementNS(xmlns, 'g');
    const $rect = document.createElementNS(xmlns, 'rect');

    $clippath.setAttributeNS(null, 'id', id);
    $g.setAttributeNS(null, 'clip-path', `url(#${id})`);
    $rect.setAttributeNS(null, 'width', '100%');
    $rect.setAttributeNS(null, 'height', '100%');

    $clippath.appendChild($path);
    $g.appendChild($rect);
    $svg.appendChild($clippath);
    $svg.appendChild($g);
  };

  const players = $containers.map($container => {
    const $audio = $container.querySelector('audio');
    const $svg = $container.querySelector('.wave-transparent svg');

    const player = new Plyr($audio, {
      controls: ['play'],
      volume: 1,
      muted: false,
    });

    $container.addEventListener('click', event => {
      if (event.target.dataset.plyr === 'play') return;

      player.togglePlay();
    });

    player.on('playing', () => {
      players.forEach(otherPlayer => {
        if (otherPlayer !== player) otherPlayer.pause();
      });
    });

    player.on('timeupdate', () => {
      const currentProgress = (player.currentTime * 100) / player.duration;
      $container.style.setProperty('--current-progress', `${currentProgress}%`);
    });

    applyClipPath($svg);
    $container.classList.remove('--initializing');

    return player;
  });
});
