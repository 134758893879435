import $ from 'jquery';

$(() => {
  $('.modal .modal-backdrop, .modal .modal-dialog__header a').on(
    'click',
    event => {
      event.preventDefault();

      $(event.target)
        .closest('.modal')
        .removeClass('--opened');
    }
  );
});
