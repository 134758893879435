import $ from 'jquery';
import { validateForm } from './validators';

$(() => {
  $('form.mailer-form').each((index, form) => {
    const $form = $(form);
    const $fieldset = $form.find('fieldset');
    const $inputs = $form.find('input, textarea');

    $form.on('submit', async event => {
      event.preventDefault();

      const { action: url, method } = form;
      const data = $form.serialize();
      const errors = validateForm($form);
      const isValid = !Object.keys(errors).length;

      // Remove all errors.
      $inputs.parent().removeClass('--invalid');

      // Apply input errors if necessary.
      Object.keys(errors).forEach(inputName =>
        $inputs
          .filter(`[name="${inputName}"]`)
          .parent()
          .addClass('--invalid')
      );

      if (!isValid) return;

      $form.addClass('--sending').removeClass(['--server-error', '--sent']);
      $fieldset.attr('disabled', 'true');

      try {
        await $.ajax({ method, url, data });
        $form.addClass('--sent');
      } catch (error) {
        $form.addClass('--server-error');
      }

      $form.removeClass('--sending');
      $fieldset.removeAttr('disabled');
    });

    $inputs.on('input change', event => {
      const $input = $(event.target);

      $input.parent().removeClass('--invalid');
    });
  });

  $('form.mailer-form-FormData').each((index, form) => {
    const $form = $(form);
    const $fieldset = $form.find('fieldset');
    const $inputs = $form.find('input, textarea');

    $form.on('submit', async event => {
      event.preventDefault();

      const { action: url, method } = form;
      var data = new FormData(form);
      const errors = validateForm($form);
      const isValid = !Object.keys(errors).length;

      // Remove all errors.
      $inputs.parent().removeClass('--invalid');

      // Apply input errors if necessary.
      Object.keys(errors).forEach(inputName =>
        $inputs
          .filter(`[name="${inputName}"]`)
          .parent()
          .addClass('--invalid')
      );

      if (!isValid) return;

      $form.addClass('--sending').removeClass(['--server-error', '--sent']);
      $fieldset.attr('disabled', 'true');

      try {
        await $.ajax({
          method,
          url,
          data,
          processData: false,
          contentType: false,
        });
        $form.addClass('--sent');
      } catch (error) {
        $form.addClass('--server-error');
      }

      $form.removeClass('--sending');
      $fieldset.removeAttr('disabled');
    });

    $inputs.on('input change', event => {
      const $input = $(event.target);

      $input.parent().removeClass('--invalid');
    });
  });
});

// Handle drag'n'drop zone
$(() => {
  document.querySelectorAll('.drop-zone__input').forEach(inputElement => {
    const dropZoneElement = document.querySelector('.drop-zone');
    const dropZonePrompt = document.getElementById('drop-zone__prompt');
    const formElement = document.querySelector('.form-bg');
    const promptWrap = document.querySelector('.prompt-wrap');
    promptWrap.addEventListener('click', e => {
      inputElement.click();
    });
    inputElement.addEventListener('change', e => {
      if (inputElement.files.length) {
        var extension = inputElement.files[0].name.split('.').pop();

        if (['pdf', 'doc', 'docx'].includes(extension)) {
          updateThumbnail(
            dropZoneElement,
            dropZonePrompt,
            promptWrap,
            inputElement.files[0]
          );
        } else {
          incorrectFileExtension(dropZoneElement, dropZonePrompt, promptWrap);
        }
      }
    });
    formElement.addEventListener('dragover', e => {
      e.preventDefault();
      dropZoneElement.classList.add('drop-zone--over');
    });
    ['dragleave', 'dragend'].forEach(type => {
      dropZoneElement.addEventListener(type, e => {
        dropZoneElement.classList.remove('drop-zone--over');
      });
    });
    dropZoneElement.addEventListener('drop', e => {
      e.preventDefault();
      if (e.dataTransfer.files.length) {
        var extension = e.dataTransfer.files[0].name.split('.').pop();
        if (['pdf', 'doc', 'docx'].includes(extension)) {
          inputElement.files = e.dataTransfer.files;
          updateThumbnail(
            dropZoneElement,
            dropZonePrompt,
            promptWrap,
            e.dataTransfer.files[0]
          );
        } else {
          incorrectFileExtension(dropZoneElement, dropZonePrompt, promptWrap);
        }
      }
      dropZoneElement.classList.remove('drop-zone--over');
    });

    function updateThumbnail(
      dropZoneElement,
      dropZonePrompt,
      promptWrap,
      file
    ) {
      // If there is a prompt, remove it
      let thumbnailElement = promptWrap.querySelector('.drop-zone__thumb');
      if (dropZonePrompt) {
        dropZonePrompt.remove();
      }

      // If there is an error, remove it
      let errorElement = promptWrap.querySelector('.drop-zone__error');
      if (errorElement) {
        errorElement.remove();
      }
      //If there is no thumbnail, create one
      if (!thumbnailElement) {
        thumbnailElement = document.createElement('div');
        thumbnailElement.classList.add('drop-zone__thumb');
        promptWrap.appendChild(thumbnailElement);
      }
      thumbnailElement.dataset.label = file.name;
    }

    function incorrectFileExtension(
      dropZoneElement,
      dropZonePrompt,
      promptWrap
    ) {
      // If there is a thumbnail, remove it
      let thumbnailElement = promptWrap.querySelector('.drop-zone__thumb');
      if (thumbnailElement) {
        thumbnailElement.remove();
      }
      // If there is a prompt, remove it
      if (dropZonePrompt) {
        dropZonePrompt.remove();
      }
      // If there is already an error, do nothing
      let errorElement = promptWrap.querySelector('.drop-zone__error');
      if (errorElement) {
        return;
      }

      //If there is no thumbnail, create one
      if (!errorElement) {
        thumbnailElement = document.createElement('div');
        thumbnailElement.classList.add('drop-zone__error');
        thumbnailElement.innerHTML = '<span>PDF, DOC & DOCX</span>';
        promptWrap.appendChild(thumbnailElement);
      }
    }
  });
});
