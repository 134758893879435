import $ from 'jquery';

const ready = function() {
  $('.js-scroll-link').on('click', function(e) {
    e.preventDefault();

    var linkOffset = -80;
    if ($($.attr(this, 'href')).data('scroll-link-offset')) {
      linkOffset += $($.attr(this, 'href')).data('scroll-link-offset');
    }

    $('.mobile-nav-sidebar').removeClass('visible');

    $('html, body').animate(
      {
        scrollTop: $($.attr(this, 'href')).offset().top + linkOffset,
      },
      500
    );
  });
};

$(document).ready(ready);
