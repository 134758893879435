import $ from 'jquery';
import likely from 'ilyabirman-likely';

import '../styles/main.scss';
import './gdpr';
import './i18n';
import './modal';
import './awakeness';
import './scroll-links';
import './waveviz';
import './nav-mobile';
import './form';
import './sample-player';
import './demo-call';
import './fastclick';
import './hide-career';
import './logo-downloads';

likely.initiate();

$(function() {
  FastClick.attach(document.body);
});
