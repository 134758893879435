import $ from 'jquery';

export const Validators = Object.freeze({
  isRequired: $input => !!$input.val(),
  isChecked: $input => $input.is(':checked'),
  isPhone: $input => /^[0-9+\s]+$/.test(($input.val() || '').trim()),
});

export const validateForm = $form =>
  $form
    .find('[data-validators]')
    .toArray()
    .reduce((formErrors, input) => {
      const $input = $(input);
      const inputName = $input.attr('name');
      const inputErrors = $input
        .data('validators')
        .split(',')
        .map(key => {
          const validator = Validators[key];
          const isNotValid = validator && !validator($input);

          return isNotValid ? key : null;
        })
        .filter(Boolean);

      return inputErrors.length > 0
        ? { ...formErrors, [inputName]: inputErrors }
        : formErrors;
    }, {});
